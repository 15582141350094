<template>
  <Card class="clueCard">
    <template slot="title">
     <div class="card-info">
      <Row type="flex" justify="space-between">
        <Cols span="18">
          <h3 class="name">{{cardInfo.qbsAccountName}}
            <img
              style="width:20px;height:auto;vertical-align: middle;"
              src="../../../../assets/images/icon/male.png"
              v-if="cardInfo.gender === '1'"
            >
            <img
              style="width:20px;height:auto;vertical-align: middle;"
              src="../../../../assets/images/icon/girl.png"
              v-if="cardInfo.gender === '0'"
            >
            <!-- <span class="tips">{{cardInfo.score}}</span> -->
          </h3>
        </Cols>
        <Cols>
          <span class="tag">{{handlestatus(cardInfo.status)}}</span>
        </Cols>
      </Row>
      </div>
    </template>
    <div class="cardinfo">
      <h3>
        <Row type="flex" justify="space-between">
          <Cols span="18">
            <Icon
              name="phone"
              size='0.3rem'
              class="cu-phone"
            />
            <span class="phonenum" >{{cardInfo.qbsAccountPhone}}</span>
          </Cols>
          <Cols>
            <span class="phonenum">{{cardInfo.threeLevelChannel}}</span>
          </Cols>
        </Row>
      </h3>
      <Row>
        <div class="card-cell ">
          <CardCell
            v-for="($item, ind)  in cardInfo.info"
            :key="ind"
            :label="$item.infoFollowup"
            :value="$item.val"
            label-width="1.5rem"
          />
        </div>
      </Row>
    </div>
    <template slot="footer">
      <div class="msg">
        <Icon name="manager" class="cu-manager" v-if="cardInfo.ownerUserName" />{{cardInfo.ownerUserName}}
      </div>
      <div class="footer-right">
        
        <a @click="wxDoChat(cardInfo.eosAccountId)">
          <img style="width:28px;margin-right:10px;" src="../../../../assets/images/icon/wechat@2x.png">
        </a>
        <a :href="`tel:${cardInfo.qbsAccountPhone}`">
          <img style="width:32px;margin-right:10px;" src="../../../../assets/images/icon/phone@2x.png">
        </a>
        <Button
          round
          type="info"
          size="small"
          @click="jumpinfo(cardInfo.eosAccountId)"
        >Details</Button>
        <Button
          round
          type="info"
          size="small"
          v-if="cardInfo.status !== 'CONVERTED' "
          @click="handlefollowup(cardInfo)"
        >Follow up</Button>
      </div>
    </template>
  </Card>
</template>
<script>
import { Button, Icon, Row, Col, Toast } from 'vant'
import { orgCode } from '@/api/chargequery'
import Card from '_c/card'
import CardCell from '_c/card/cardCell'
import '@/style/vant-patch.less'
import '@/style/repairIcon.less'
import { wxDoChat } from '@/utils/wechart'
export default {
  name: 'CardItem',
  props: {
    cardInfo: {
      type: Object,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      wxDoChat,
    }
  },
  components: {
    Card,
    Button,
    CardCell,
    Icon,
    Row,
    Cols: Col
  },
  methods: {
    init () {
      // console.log(111)
    },
    // 
    handlefollowup (cardInfo) {
      this.$router.push({
        path: '/cluesFollowUpFrom',
        query: {
          eosLeadsId: cardInfo.eosLeadsId
        }
      })
    },
    // 点击电话按钮
    handlephine (cardinfo) {
      this.callphone = cardinfo.qbsAccountPhone
      this.orgCode()
    },
    async orgCode () {
      const res = await orgCode({ orgCode: this.callphone }) //
      if (res.code === '000000') {
        Toast('正在拨打')
      } else {
        Toast(res.msg)
      }
    },
    // 跳转客户详情
    jumpinfo (accountId) {
      this.$router.push({
        path: '/customerInfo',
        query: { 'id': accountId }
      })
    },
    // 线索状态处理
    handlestatus (status) {
      if (status === '4') return '活动'
      if (status === 'OPEN') return 'Open'
      if (status === 'ATTEMPTING_TOUCHING') return '待联系'
      if (status === 'CLOSE_NO_TOUCHING') return '无法联系'
      if (status === 'IN_TOUCHING') return '跟进中'
      if (status === 'TD_SCHEDULED') return '已预约试驾'
      if (status === 'CLOSELOST') return '暂败'
      if (status === 'CONVERTED') return '已转化'
      if (status === 'TD') return '完成试驾'
      // if (status === 'OPEN') return 'Open'
      if (status === 'FOLLOW_UP') return '跟进回访'
      if (status === 'LONG_TERM') return '短期无意向'
      if (status === 'CLOSE_DUPLICATE') return '重复关闭'
      if (status === 'DEPOSIT_RESERVED') return '意向金'
      if (status === 'CLOSE_LOST') return '暂败'
      if (status === 'ORDER') return '大定'
      if (status === 'CLOSE_CANCELLED') return '退订'
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>